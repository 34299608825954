import React from 'react';
import styled from "styled-components";
import { BsWhatsapp } from "react-icons/bs";


export default function ChatWithUs() {
    return (
        <Chat>
            <a
                href="https://wa.me/254790666127?text=Hello%20I'm%20inquiring%20about%20An%20Apartment%20Relocation"
                target="_blank"
                rel="noopener noreferrer">
                <div className='chat-container'>
                    <BsWhatsapp size={30} />
                    <span>Chat With Us</span>
                </div>
            </a>
        </Chat>

    )
}

const Chat = styled.div`
.chat-container{
  background-color: green;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: .5rem;
  position: fixed;
  width: 12%;
  border-radius: 13px;
    height: 60px;
    bottom: 40px;
    left: 40px;
    cursor: pointer;
    z-index: 100;
    color: #fff;
    font-size: 1rem;
}

span{

}
@media screen and (min-width: 280px) and (max-width: 720px) {
    .chat-container{
      background-color: green;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: .5rem;
  position: fixed;
  width: 30%;
  border-radius: 13px;
    height: 60px;
    bottom: 105px;
    left: 33px;
    cursor: pointer;
    z-index: 100;
    color: #fff;
    font-size: 1rem;
}
  }
`;