import React from "react";
import styled from "styled-components";
import homeImage from "../assets/images/moving.jpg"
export default function Hero() {
  return (
    <Section id="hero">
      <div className="background">
        <img src={homeImage} alt="" />
      </div>
      <div className="content">
        <h1 className="contact">TEL: +2547 906 66127</h1>
        <div className="title">
          <h1>Welcome to Cossy Movers.</h1>
          <h2>We are trusted Movers in Kenya. </h2>
          <h2>We guarantee your Seamless Move.</h2>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  width: 100%;
  height: 100%;

  .background {
    height: 100%;
    img {
      width: 100%;
      height: 100vh;
      filter: brightness(70%);
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    diaplay: flex;
    padding-top: .5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    .contact{
      font-weight: 900;
      font-size: 2.5rem;
      color: blue;
      letter-spacing: 0.1rem;
      background-color: white;
    }
    .title {
      color: white;
      h1 {
        font-size: 2.5rem;
        font-weight: 900;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 1.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      background-color: #ffffffce;
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #ffa776;
        }
      }
    }
  }
  .serviceTitle{
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 25rem;
    .background {
      background-color: palegreen;
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 2rem;
        }
        h2{
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
          padding: 1vw;
        }
        
      }
      .contact{
          font-weight: 900;
          font-size: 1.5rem;
          color: blue;
          letter-spacing: 0.1rem;
          background-color: white;
        }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
