import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/images/logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { FiPhoneCall } from "react-icons/fi";
import Social from "./Social";
export default function Navbar() {
  const [navbarState, setNavbarState] = useState(false);
  return (
    <>
      <Nav>
        <div className="brand">
          <div className="container">
            <img className="logo" src={logo} alt="logo" />
            {/* Cossy Movers */}
          </div>
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavbarState(true)} />
            )}
          </div>
        </div>
        <div className="menus">
          <div className="top">
            <a href="tel:254790666127"> <FiPhoneCall /> <h3 className="call-us">+2547-9066-6127</h3></a>
            <Social />
          </div>
          <div className="bottom">
            <ul>
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about-us">About</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              <li>
                <a href="#team">Our Team</a>
              </li>
              <li>
                <a href="#testimonials">Testimonials</a>
              </li>
            </ul>
            <button> <a href="#contactUs">Get A Free Quote</a></button>

          </div>
        </div>


      </Nav>
      <ResponsiveNav state={navbarState}>
        <ul>
          <li>
            <a href="#home" onClick={() => setNavbarState(false)}>
              Home
            </a>
          </li>
          <li>
            <a href="#about-us" onClick={() => setNavbarState(false)}>
              About Us
            </a>
          </li>
          <li>
            <a href="#services" onClick={() => setNavbarState(false)}>
              Services
            </a>
          </li>
          <li>
            <a href="#testimonials" onClick={() => setNavbarState(false)}>
              Testimonials
            </a>
          </li>
        </ul>
      </ResponsiveNav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .brand {
    .container {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .logo{
      width: 100%;
      height: 100%;
      object-fit: cover;
      overflow: hidden;
    }
    .toggle {
      display: none;
    }
  }
  .menus{
    margin-left: 10%;
    width: 100%;
    align-items: center;
    .top{
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        span, a{
          display: flex;
          align-items: center;
          .call-us{
            margin-left: 1rem;
          }

        }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      display: flex;
      ul {
      display: flex;
      gap: 1rem;
      list-style-type: none;
      li {
        a {
          text-decoration: none;
          color: #0077b6;
          font-size: 1.2rem;
          transition: 0.1s ease-in-out;
          &:hover {
            color: #ffa776;
          }
        }
        &:first-of-type {
          a {
            color: #ffa776;
            font-weight: 900;
          }
        }
      }
    }
  }
  
  button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    color: white;
    background-color: orange;
    font-size: 1.1rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: #48cae4;
    }
    a{
      color: white;
      text-decoration: none;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        display: block;
      }
    }
    .bottom {
      display: none;
    }
    .top{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        span{
          display: flex;
          align-items: center;
          .call-us{
            margin-left: .1rem;
          }

        }
        h3{
          font-size: .8rem;
        }
    }
    button {
      display: none;
    }
  }
`;

const ResponsiveNav = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: ${({ state }) => (state ? "50px" : "-400px")};
  background-color: white;
  height: 30vh;
  width: 100%;
  align-items: center;
  transition: 0.3s ease-in-out;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;

      a {
        text-decoration: none;
        color: #0077b6;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #ffa776;
        }
      }
      &:first-of-type {
        a {
          color: #ffa776;
          font-weight: 900;
        }
      }
    }
  }
`;
