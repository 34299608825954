import React from 'react';
import styled from "styled-components";

import MoveHome from "../assets/images/move.jpeg";
import Truck from "../assets/images/cossy-truck.png";

export default function About() {
    return (
        <Section id="about-us">
            <h1 className='header'>About Us</h1>
            <div className="about">
                <img src={Truck} alt='cossy-movers' />
                <div className='about-text'>
                    <p className='sub-heading'>Cossy Movers Ltd.</p>
                    <span>
                        <p>
                            Cossy Movers is your smart and trusted relocation, moving and storage company that was incorporated in March 2013 and started operating in January 2015. As the best moving solutions provider in Nairobi Kenya, we guarantee you seamless and cheap home moving, office moving and relocation, general cleaning services, tv mounting and DStv installation services, among other services. The company is managed by indigenous Kenyans with more than 100 years combined experience in the industry.
                        </p>
                        <p>
                            As Kenya’s leading premium removals service provider, we ensure that the anxiety of moving is replaced with the delightful convenience that only true experts can provide. Cossy Movers has highly trained enthusiastic staff who are recruited after vigorous background checks to ensure we have only the best.
                        </p>

                        <p>
                            Our superior equipment and packaging materials ensure your personal goods not only move in style but are also handled with utmost care.With several years of experience, industry knowledge and lots of satisfied customers, we move you without tears!

                        </p>
                    </span>
                </div>
            </div>
            <div className="promise">
                <p className='sub-heading'>Our Promise</p>
                At Cossy Movers Limited you are guaranteed offer personalized solutions. We will continuously improve our services through technical expertise, customer centric approach in all our processes and respect the environment. In so doing, we will nurture the talents of our employees to achieve personal growth while enjoying what we do.
            </div>
            <div className="vision">
                <div className='vision-text'>
                    <h2 className='sub-heading'>Our Vision and Core Values</h2>
                    Cossy Movers will lead in defining excellence in relocation and storage by ensuring quality service delivery and delight.
                    <ul>
                        <li>Proffesionalism</li>
                        <li>Courtesy</li>
                        <li>Creativity</li>
                        <li>Teamwork</li>
                        <li>Enthusiasm</li>
                        <li>Care</li>
                    </ul>
                </div>
                <img src={MoveHome} alt='cossy-movers' />

            </div>
        </Section>
    )
}

const Section = styled.section`
margin-top: 10%;

.about{
    margin-top:  2%;
    padding-top:  1rem;
    display: flex;
    img{
        width: 50%;
        height 50vh;
        padding-right:  3rem;
    }
    .about-text{
       width: 50%; 
       h1{
        margin: 1%;
        font-size: 2rem;
        font-weight: 900;
       }
    }
}
.promise{
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 3rem;
    background-color: aliceblue;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
.vision{
    margin-top:  3%;
    margin-bottom:  10%;
    padding-top:  2rem;
    display: flex;
    img{
        width: 50%;
        height 50vh;
    }
    .vision-text{
        padding-left:  3rem;
        padding-right:  3rem;
        width: 50%; 
        h2{
             padding: 1%;
        }
        ul{
            margin: 5%;
        }
    }
}
@media screen and (min-width: 280px) and (max-width: 720px) {
.about{
    margin-top:  10%;
    display: flex;
    flex-direction: column;
    img{
        width: 100%;
        height 100vh;
    }
    .about-text{
       width: 100%; 
       h1{
         padding: 10%;
        margin: 1%;
        font-size: 2rem;
        font-weight: 900;
       }
    }
}
.promise{
    margin: 10%;
    h2{
        padding: 10%;
    }
}
.vision{
    margin-top:  10%;
    padding-top:  3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
        width: 100%;
        height 50vh;
    }
    .vision-text{
        width: 100%; 
        h2{
             padding: 1%;
        }
        ul{
            margin: 10%;
            padding-left: 3rem;
        }
    }
}

  }
`;
