import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import Modal from "./modal";
import { Button } from "./modal/modal.styles";


const Contact = () => {
  const form = useRef();
  const [submitting, setSubmitting] = useState(true);
  const [open, setOpen] = React.useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSubmitting(true);
    emailjs.sendForm('service_sm4zgx8', 'template_8igawtn', form.current, 'opk4omcuxb63uYdDP')
      .then(res => {
        setSubmitting(false);
        setOpen(true);
      }).catch(err => {
        console.log(err);
      });
  };
  return (
    <Section id="contactUs">
      <Modal
        active={open}
        hideModal={() => setOpen(false)}
        title="Success!"
        footer={<Button onClickCapture={() => setOpen(false)}>OKAY</Button>}
      >
        <p>Your message hass been sent and we'll send you a quote shorlty. </p>
        <p>Thank you for your interest and have a great day!</p>
      </Modal>
      <StyledContactForm>
        <div className="header">
          <div className="header">
            Get A Free Quote
          </div>
          <span>Call us to get a free quote instantly!</span>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <div className="full-name">
            <label>Full Name</label>
            <input type="text" name="user_name" />
          </div>
          <div className="container1">
            <div className="form-item">
              <label>Primary Phone</label>
              <input type="mobile" name="user_mobile" required />
            </div>
            <div className="form-item">
              <label>Primary Email</label>
              <input type="email" name="user_email" required />
            </div>
          </div>
          <div className="container2">
            <div className="form-item">
              <label>Moving From</label>
              <input type="text" name="moving_from" required />
            </div>
            <div className="form-item">
              <label>Moving To</label>
              <input type="text" name="moving_to" required />
            </div>
          </div>
          <label>Additional Info</label>
          <textarea name="message" />
          <input type="submit" value="GET QUOTE" />
        </form>
      </StyledContactForm>
    </Section>

  );
};

export default Contact;

// Styles
const Section = styled.section`
display: flex;
align-items: center;
justify-content: center;
`;

const StyledContactForm = styled.div`
  margin: 1rem 0.5rem;
  width: 60%;
  .header {
    text-align: center;
    margin-bottom: 2rem;
    .contactTitle {
      text-align: center;
      margin-bottom: 2rem;
      font-size: 25px;
      font-weight: bold;
    }
    span{
      font-size: 15px;
    }
  }
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 14px;
    .full-name{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 35px;
    }
    .container1{
      display: flex;
      width: 100%;
      margin-top: 3rem;
      justify-content: space-between;
      .form-item{
        width: 49%;
      }
    }
    .container2{
      display: flex;
      width: 100%;
      margin-top: 1rem;
      justify-content: space-between;
      .form-item{
        width: 49%;
      }
    }
    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid #ffa776;
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid #ffa776;
      }
    }
    label {
      margin-top: 1rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: #ffa776;
      color: white;
      border: none;
    }
  }
    @media screen and (min-width: 280px) and (max-width: 1080px) {
      width: 100%;
      margin: 0;
    }
`;