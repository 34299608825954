import React from "react";
import styled from "styled-components";
import logo from "../assets/images/logo.png";
import { FiPhoneCall } from "react-icons/fi";
import { AiOutlineMail } from "react-icons/ai";
import { ImLocation } from "react-icons/im";
import Social from "./Social";

export default function Footer() {
  return (
    <FooterContainer>
      <div className="topContainer">
        <div className="col1">
          <img src={logo} loading="lazy" alt="footer-logo" className="footer-logo" />
        </div>
        <div className="col2">
          <span className="col2-desc">Experience the delight of taking breakfast at your old house and lunch at your new house, All seamlessly.</span>
          <div className="social">
            <h4>Follow Us</h4>
            <Social />
          </div>
        </div>
        <div className="col3">
          <h3 className="sub-heading">Contact Information</h3>
          <div className="col3-item"><ImLocation /> <span>Jogoo Road - Nairobi</span> </div>
          <div><AiOutlineMail /> <a href="mailto:cossymovers@gmail.com, nandwere.mn@gmail.com">cossymovers@gmail.com</a></div>
          <div className="col3-item"><FiPhoneCall /> <a href="tel:254790666127">+2547-9066-6127</a></div>
        </div>

      </div>
      <div className="bottomContainer">
        <span>Copyright &copy; {new Date().getFullYear()} Cossy Movers. All rights reserved. Powered By(SoftMax)</span>
      </div>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  background-color: aliceblue;
  flex-direction: column;
  border-radius: 0.5rem;
  margin-top: 2.5rem;
  .topContainer{
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    display: flex;
    .col1 {
      height: 100%;
      padding: 2rem;
      width: 35%;
      .footer-logo{
        background-size: contain;
      }
      .col1-desc {
        padding: 1rem;
      }
    }
    .col2 {
      max-width: 35%;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .social{
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        justify-content: center;
        align-items: center;
      }
    }
    .col3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem;
      span, a{
        padding-left: 1rem;
        font-size: 1rem;
        font-weight: 700;
      }
    }
  }
  .bottomContainer {
    display: flex;
    color: #fff;
    padding: 1.5rem;
    justify-content: space-evenly;
    background-color: #000;
    
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    .topContainer{
      width:100%;
      display: flex;
      flex-direction: column;
      .col1 {
        height: 250px;
        width: 100%;
        margin: 1rem;
        .footer-logo{
          height: 100%;
          background-size: contain;
        }
        .col1-desc {
          padding: 1rem;
        }
      }
      .col2 {
        max-width: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }
`;
