import React from 'react';
import styled from "styled-components";


export default function ChooseUsItem({ title, icon, text }) {
    return (
        <ChooseUsItemWrapper className='itemContainer'>
            <img src={icon} alt="" />
            <div className='textContainer'>
                <h3 className='sub-heading'>{title}</h3>
                <p>{text}</p>

            </div>
        </ChooseUsItemWrapper>
    )
}

const ChooseUsItemWrapper = styled.div`
width: 45%;
box-sizing: border-box;
margin: 5px;
height: 150px;
display: flex;
img{
    max-width: 15%;
    height: 80px;
    padding: 5px;
}
.textContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left:1rem;
    h3{
padding-bottom: 1rem;
    }
    p{

    }
}
  @media screen and (min-width: 280px) and (max-width: 720px) {
    width: 100%;
    margin-bottom: 3rem;
    padding-top: 2rem;
    .h3{
        padding-top: 1rem;
    }
  }
`;

