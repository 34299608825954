import React from 'react';
import styled from "styled-components";
import LinkedIn from "../assets/images/icons8-linkedin-circled.gif";
import Facebook from "../assets/images/icons8-facebook-circled.gif";
import Instagram from "../assets/images/icons8-instagram.gif";
import Twitter from "../assets/images/icons8-twitter-circled.gif";

export default function Social() {
    return (
        <SocialWrapper>
            <a href="https://twitter.com/cossymovers" target="_blank" rel='noreferrer'>
                <img
                    src={Twitter}
                    alt="Twitter"
                    loading="lazy"
                    className='item'
                />
            </a>
            <a href="https://www.instagram.com/cossymovers/" target="_blank" rel='noreferrer' >
                <img
                    src={Instagram}
                    alt="Instagram"
                    loading="lazy"
                    className='item'
                />
            </a>
            <a href="https://linkedin.com/cossymovers" target="_blank" rel='noreferrer'>
                <img
                    src={LinkedIn}
                    alt="Linkedin"
                    loading="lazy"
                    className='item'
                />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100089069505477" target="_blank" rel='noreferrer'>
                <img src={Facebook}
                    alt="facebook"
                    className='item'
                />
            </a>
        </SocialWrapper>
    )
}

const SocialWrapper = styled.footer`
display: flex;
justify-content: space-evenly;
.item{
    width: 2.5rem;
    cursor: pointer;
}
`;
